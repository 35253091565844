<script>
//import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Summary from "./home-summary";
import Recent from "./home-recent";
import HomeProjects from "./home-projects";
import ActivityChart from "@/views/pages/dashboard/home-activity-chart";
import HomeApps from "@/views/pages/dashboard/home-apps";
import Empty from "@/views/pages/dashboard/home-empty";
import Constant from "@/data/Constants";
import HttpRequest from "@/http/HttpRequest";
import OnlineActives from "@/views/pages/dashboard/online-actives";
import NewVersionAlert from "./new-version-alert";
import AppUsers from "./app-users";
import Vue from 'vue'
import Preference from "@/data/Preference"
let preference = new Preference()

let httpRequest = new HttpRequest()
export default {
  components: {
    AppUsers,
    ActivityChart,
    Layout: () => import('../../layouts/main'),
    PageHeader,
    Summary,
    Recent,
    HomeProjects,
    HomeApps,
    Empty,
    OnlineActives,
    NewVersionAlert
  },
  data() {
    return {
      role: preference.getData(Preference.KEY_ROLE_ID),
      title: "Dashboard (Last seven days)",
      items: [
        {
          text: "WORKANY",
          to:'/'
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      scope: Constant.DASHBOARD_SCOPE_ALL,
      totalProjects: 0,
      loadAllData: true,
      showOnlyMyData: false,
      isEmpty: false,
      isLoading: true
    };
  }, methods: {
    emptyListener(isEmpty) {
      this.isEmpty = isEmpty
      if (isEmpty && this.scope === Constant.DASHBOARD_SCOPE_ALL) {
        this.showOnlyMyData = true
        this.isEmpty = false
        this.loadAllData = false
        this.scope = Constant.DASHBOARD_SCOPE_ME
      }
    }, loadingListener(isLoading) {
      this.isLoading = isLoading
    }, getTotalProjects(totalProjects) {
      this.totalProjects = totalProjects
    }, all() {
      this.isEmpty = false
      this.loadAllData = true
      this.scope = Constant.DASHBOARD_SCOPE_ALL
    }, me() {
      this.isEmpty = false
      this.loadAllData = false
      this.scope = Constant.DASHBOARD_SCOPE_ME
    }, tokenExpiryListener() {
      let _this = this
      httpRequest.setAuthorizationCallback(function () {
        _this.$router.push({path: '/logout'});
      })
    }
  }, async created() {
    this.tokenExpiryListener()
  }
};
export const bus = new Vue()
</script>

<template>
  <Layout>
    <!--page title & breadcrumb-->
    <PageHeader :title="title" :items="items"/>
    <div>
      <NewVersionAlert :scope="scope"  ></NewVersionAlert>
    </div>

    <!--view switch-->
    <div class="row" v-if="!showOnlyMyData && !isLoading">
      <div class="col-lg-12">
        <div class="btn-group mb-3" data-toggle="buttons">
          <button type="button" class="btn btn-sm btn-light" v-on:click="all" :class="{active: loadAllData }">All
          </button>
          <button type="button" class="btn btn-sm btn-light" v-on:click="me" :class="{active: !loadAllData }">Me
          </button>
        </div>
      </div>
    </div>

    <div v-if="this.role == 3 || this.role == 2 || this.role == 4">
      <div class="row" v-if="!isEmpty">
        <div class="col-lg-12">
          <Summary :scope="scope" :totalProjects="totalProjects" @isEmpty="emptyListener" @isLoading="loadingListener"/>
        </div>
      </div>

      <!--recent & chart activity block-->
      <div class="row" v-if="!isEmpty">
        <div class="col-lg-6">
          <Recent :scope="scope"/>
        </div>
        <div class="col-lg-6">
          <OnlineActives :scope="scope"/>
        </div>
      </div>

      <!--Apps & project-->
      <div class="row" v-if="!isEmpty">
        <div class="col-lg-6">
          <ActivityChart :scope="scope"/>
        </div>
        <div class="col-lg-6 mb-4">
          <HomeApps :scope="scope"/>
        </div>
      </div>

      <div class="row" v-if="isEmpty">
        <div class="col-lg-12 my-4">
          <Empty/>
        </div>
      </div>

      <div class="row" v-if="!isEmpty">
        <div class="col-lg-6 mb-4">
          <HomeProjects :scope="scope" @totalProject="getTotalProjects"/>
        </div>
        <div class="col-lg-6 mb-4">
          <AppUsers :scope="scope" />
        </div>
      </div>

    </div>

    <div v-else>
      <!--home summary block-->
      <div class="row" v-if="!isEmpty">
        <div class="col-lg-12">
          <Summary :scope="scope" :totalProjects="totalProjects" @isEmpty="emptyListener" @isLoading="loadingListener"/>
        </div>
      </div>

      <!--recent & chart activity block-->
      <div class="row" v-if="!isEmpty">
        <div class="col-lg-6">
          <Recent :scope="scope"/>
        </div>
        <div class="col-lg-6">
          <ActivityChart :scope="scope"/>
        </div>
      </div>

      <!--Apps & project-->
      <div class="row" v-if="!isEmpty">
        <div class="col-lg-6 mb-4">
          <HomeApps :scope="scope"/>
        </div>
        <div class="col-lg-6 mb-4">
          <HomeProjects :scope="scope" @totalProject="getTotalProjects"/>
        </div>
      </div>
      <div class="row" v-if="isEmpty">
        <div class="col-lg-12 my-4">
          <Empty/>
        </div>
      </div>
    </div>


  </Layout>
</template>

<style scoped>

.customs-bg-color {
  background-color: #4b1fe5 !important;
}
</style>
