<script>
import HttpRequest from "@/http/HttpRequest"
import Utils from "@/helpers/Utils";

let httpRequest = new HttpRequest()

export default {
  props: ['scope'],
  data() {
    return {
      isLoading: false,
      projectData: [],
      projectLink:'/projects',
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      fields: [
        // {key: "header", sortable: false, label: ""},
        {key: "project_name", sortable: false, label: "Project Name"},
        {key: "work_hour", sortable: true, label: "Total Worked"},
        {key: "percent", sortable: true, label: "Percent"}
      ]
    };
  }, methods: {
    getProjects() {
      let _this = this
      let fromDate = Utils.getDaysAgo(7)
      let toDate = Utils.getToday()
      let timeZone = Utils.getMyTimezone()

      _this.isLoading = true
      httpRequest.getDashboardProjects(fromDate, toDate, timeZone, this.scope).then(function (response) {
        _this.isLoading = false
        if (response.success) {

          _this.projectData = []
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i]
            let activeTime = data.total_time - data.idle_time
            let totalHours = Utils.formatTime(data.total_time)
            let percent = Math.round((activeTime * 100) / data.period_time)
            let item = {
              'project_name': data.project_name,
              // 'project_name_char': data.project_name.charAt(0).toUpperCase(),
              'work_hour': totalHours,
              'percent': percent
            }
            _this.projectData.push(item)
          }
          _this.$emit('totalProject', response.data.length)
        }
      })
    },
  }, async created() {
    await this.getProjects()
  }, watch: {
    scope: function () {
      this.getProjects()
    }
  }
};
</script>

<template>
  <div class="card h-100">
    <div class="card-body">
      <h4 class="card-title mb-3">Projects</h4>
      <b-table
          class="mt-4 mb-0"
          :items="projectData"
          :fields="fields"
          :striped="false"
          responsive=""
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isLoading"
      >

        <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
          <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label }}</span>
        </template>

<!--        <template #cell(header)="row">-->
<!--          <div class="avatar-xs">-->
<!--            <span class="avatar-title rounded-circle bg-light text-body">{{ row.item.project_name_char }}</span>-->
<!--          </div>-->
<!--        </template>-->

        <template v-slot:cell(project_name)="row">
          <div class="mt-1">
            <b-link :href="projectLink" style="color: #343a40" target="_blank">{{ row.item.project_name }}</b-link>
          </div>
        </template>


        <template #cell(percent)="row">
          <b-progress height="5px"  variant="primary" :value="row.item.percent" class="mt-2"></b-progress>
        </template>


        <template #cell(work_hour)="row">
          <div class="mt-1">{{ row.item.work_hour }}</div>
        </template>

        <template #table-busy>
          <div class="text-center">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </div>

    <div class="card-footer bg-white">
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-md-right">
            <b-pagination v-model="currentPage" :limit="3" :total-rows="totalRows" :per-page="perPage"
                          class="pagination-rounded mb-0"></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
