<script>

import HttpRequest from "@/http/HttpRequest";
let httpRequest = new HttpRequest()

export default {
  props: ['scope'],

  data() {
    return {
      isLatestVersionApp:false
    }
  },


  methods: {
    isLatestVersionApps(){
      let _this = this
      let updatedVersion = ''
      let currentVersion =''
      httpRequest.getNewVersionNumber().then(function (NewVersionNumber) {
        if (NewVersionNumber.success) {
          updatedVersion = NewVersionNumber.data.version
          httpRequest.getCurrentVersionNumber().then(function (CurrentVersionNumber) {
            if (CurrentVersionNumber.success) {
              currentVersion = CurrentVersionNumber.data.app_version
              if(updatedVersion == currentVersion)
              {
                _this.isLatestVersionApp = false
              }else{
                _this.isLatestVersionApp = true
              }
            }
          })
        }
      })

    }

  },
  async created() {
    this. isLatestVersionApps()

  }
}


</script>



<template>
  <Layout>
    <div class="">

      <b-alert v-if="isLatestVersionApp" class="text-center" show variant="danger">
        You are using an outdated version of the WorkAny application. <a href="/download">Upgrade</a> now to enjoy enhanced features and a smoother experience.
      </b-alert>
    </div>
  </Layout>
</template>
<style scoped>
</style>