<script>
import HttpRequest from "@/http/HttpRequest"
import Utils from "@/helpers/Utils"
import Preference from "@/data/Preference";
import {bus} from "@/views/pages/dashboard/index";
// import Constants from "@/data/Constants"


let preference = new Preference()

let httpRequest = new HttpRequest()

export default {
  props: ['scope'],
  data() {
    return {
      isLoading: false,
      isImageLoading: true,
      recentData: [],
      selectedFile: null,
      userNameAndAssignedProjectName: null,
      delay: preference.getData(Preference.KEY_AUTO_REFRESH_TIME),
      runner: null,

    };
  }, methods: {
    getRecentData() {

      let _this = this
      let fromDate = Utils.getDaysAgo(7)
      let toDate = Utils.getToday()
      _this.isLoading = true
      httpRequest.getRecent(fromDate, toDate, this.scope).then(function (response) {
        _this.isLoading = false
        if (response.success) {
          _this.recentData = []
          _this.recentData = response.data
          _this.isImageLoading = true

          _this.getImage()
        }
      })
    },
    getImage() {
      let _this = this
      for (let i = 0; i < _this.recentData.length; i++) {
        // _this.recentData[i].file = Constants.CLOUDEFRONT_SIGNED_URL + _this.recentData[i].file
        // _this.recentData[i].thumb = Constants.CLOUDEFRONT_SIGNED_URL + _this.recentData[i].thumb
        _this.recentData[i].percent = Math.round(((_this.recentData[i].total_time - _this.recentData[i].idle_time) * 100) / _this.recentData[i].total_time)
        
        httpRequest.generateImageUrl(_this.recentData[i].file).then(function (response) {
          if (response.success) {
            _this.recentData[i].file = response.data.URL
          }
        })


        httpRequest.generateImageUrl(_this.recentData[i].thumb).then(function (response) {
          if (response.success) {
            _this.isImageLoading = false
            _this.recentData[i].thumb = response.data.URL
          }
        })

        // if (_this.recentData[i].file) {
        //   httpRequest.generateImageUrl(_this.recentData[i].file).then(function (response) {
        //     if (response.success) {
        //       _this.recentData[i].file = Constants.CLOUDEFRONT_SIGNED_URL + 'thumb_1676614208631_739356_5798_1676614055785_img.jpg'
        //       let percent = Math.round(((_this.recentData[i].total_time - _this.recentData[i].idle_time) * 100) / _this.recentData[i].total_time)
        //       if (percent > 99) {
        //         _this.recentData[i].percent = 96
        //       } else {
        //         _this.recentData[i].percent = percent
        //       }
        //     }
        //   })
        // } else {
        //   _this.recentData[i].file = null
        //   let percent = Math.round(((_this.recentData[i].total_time - _this.recentData[i].idle_time) * 100) / _this.recentData[i].total_time)
        //   if (percent > 99) {
        //     _this.recentData[i].percent = 96
        //   } else {
        //     _this.recentData[i].percent = percent
        //   }
        // }
        _this.recentData[i].type = Utils.getActivityType(_this.recentData[i].type)
      }
    }, invokeModal(modalId, index) {
      this.selectedFile = this.recentData[index].file
      let userName = this.recentData[index].user
      let userAssignedProjectName = this.recentData[index].project
      this.userNameAndAssignedProjectName = userName + " | " + userAssignedProjectName

      if (this.selectedFile != null) {
        this.$bvModal.show(modalId)
      } else {
        this.$bvToast.toast('Either removed or you don\'t have enough permission to see this!', {
          title: 'No screenshot!',
          variant: 'warning',
          solid: true
        })
      }
    },
    getEditCommentTooltip(type, comment) {
      if (comment) {
        return type + " | " + comment
      }

    },
  }, async created() {
    // auto refresh by updated value
    bus.$on('auto_refresh_val', data => {
      this.delay = data
      clearInterval(this.runner)
      if (this.delay > 0) {
        this.runner = setInterval(this.getRecentData, this.delay * 60 * 1000)
      }
    })

    await this.getRecentData()
    // auto refresh by previously set value
    clearInterval(this.runner)
    if (this.delay > 0) {
      setInterval(await this.getRecentData, this.delay * 60 * 1000)
    }
  }, watch: {
    isImageLoading(){
      this.isImageLoading = false

    },
    scope: function () {
      this.getRecentData()
    }
  }
};
</script>

<template>
  <div class="mb-4">
    <div class="card h-100-" style="height: 425px" v-if="!isLoading">
      <div class="card-body">
        <h4 class="card-title mb-4">Recent Activity</h4>
        <div>
          <div class="row">
            <div class="col-4 mb-4" v-for="(data, index) in recentData" :key="index">
              <div class="row" v-if="isImageLoading">
                <div class="col-12" style="min-height: 118px;">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="text-center mt-4 mb-4">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isImageLoading" style="background: #eeeeee; cursor: pointer;" v-b-tooltip.hover.html="tipMethod" :title="recentData[index].user +'   |    '+ recentData[index].project" v-on:click="invokeModal('img-view', index)"
                   class="card"  >
                <b-img  class="card-img" :src="data.thumb?data.thumb:data.file"  height="90px" width="100%"
                       style="object-fit: cover;"></b-img>
                <div class="card-img-overlay d-flex">
                   <span v-if="data.edited"
                         :class="['badge badge-pill align-self-center mx-auto','badge-info']">
                                  Edited</span>
                  <span
                      v-else
                      :class="['badge badge-pill align-self-center mx-auto', (data.percent <= 30 ? 'badge-danger' : 'badge-primary'), (data.percent <= 60 ? 'badge-warning' : 'badge-primary'), (data.percent > 60 ? 'badge-success' : 'badge-primary')]">{{
                      data.percent
                    }}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="img-view" size="xl" :title="userNameAndAssignedProjectName" title-class="font-18" hide-footer>
        <img :src="selectedFile" width="100%"  style="min-height: 500px">
      </b-modal>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-12" style="min-height: 118px;">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center mt-4 mb-4">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
