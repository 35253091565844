<script>
import HttpRequest from "@/http/HttpRequest"

let httpRequest = new HttpRequest()

export default {
  props: ['scope'],
  data() {
    return {
      isLoading: false,
      appData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 6,
      total_users: 0,
      filterMembers: null,
      //count_project_member
      fields: [
        {key: "user_name", sortable: false, label: "Name"},
        {key: "email", sortable: true, label: "Email"},
        {key: "Version", sortable: true, label: "App Version"}
      ]
    };
  }, methods: {
    getUsersAppVersion: function () {
      let _this = this
      _this.isLoading = true
      httpRequest.getWorkSpaceMembersList().then(function (response) {
        _this.isLoading = false
        _this.workSpaceUsersList = response.data
        if (response.success) {
          httpRequest.getNewVersionNumber().then(function (NewVersionNumber) {
            if (NewVersionNumber.success) {
              _this.appData = []
              _this.totalRows = 0
              _this.total_users =  response.data.length
              _this.workSpaceUsersList.forEach(function (userData) {

                if(userData.app_version != NewVersionNumber.data.version){
                  _this.totalRows ++
                  let item = {
                    'user_name': userData.name,
                    'email': userData.email,
                    'Version': userData.app_version
                  }
                  _this.appData.push(item)
                }

              })

            }
          })


        }
      })
    }
  }, async created() {
    await this.getUsersAppVersion()
  }, watch: {
    filterMembers(){
      this.currentPage =  1
    },
    scope: function () {
      this.getUsersAppVersion()
    }
  }
};
</script>

<template>
  <div class="card h-100- " style="height: 435px">
    <div class="card-body">
      <div id="members-table_filter" class="d-flex justify-content-between align-items-center dataTables_filter ">
        <div class="d-flex">
          <h4 class="card-title mb-3">Outdated App Users ({{totalRows +'/'+  total_users}})</h4>

        </div>
        <div>
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
                v-model="filterMembers"
                type="search"
                class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>

      </div>

      <b-table
          class="mt-1 mb-0"
          :items="appData"
          :fields="fields"
          :striped="false"
          responsive=""
          :per-page="perPage"
          :filter="filterMembers"
          :current-page="currentPage"
          :busy="isLoading">

        <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
          <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label }}</span>
        </template>

        <template v-slot:cell(email)="data">
          <a  v-b-tooltip.hover.html="tipMethod"  target="_blank" :href="'mailto:' + data.item.email" class="cursor-pointer-custom text-dark"
                       >{{data.item.email}}</a>
        </template>


      </b-table>



    </div>

    <div class="card-footer bg-white">
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-md-right">
            <b-pagination v-model="currentPage" :limit="3" :total-rows="totalRows" :per-page="perPage"
                          class="pagination-rounded mb-0"></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff7c47;
  border-color: #4b1fe5;
}
.cursor-pointer-custom{
  cursor: pointer;
}
</style>
